export const INTERVENTION = {
    RADIO_BUTTONS_KEYS: {
        ACTIVE: 'PROCESSING',
        CLOSED: 'TODO',
        PAYMENT: 'TO_BE_PAID'
    },
    PURCHASE_STATUS: {
        PROCESSING: 'PROCESSING',
        TODO: 'TODO',
        TO_BE_PAID: 'TO_BE_PAID'
    },
    PURCHASE_SUB_STATUS: {
        EXPECT_DATE_VISIT: 'EXPECT_DATE_VISIT',
        EXPECT_QUOTATION: 'EXPECT_QUOTATION',
        EXPECT_DOC: 'EXPECT_DOC',
        EXPECT_DATE_INTERVENTION: 'EXPECT_DATE_INTERVENTION'
    },
    DOCUMENT_TYPES: {
        PICS_BEFORE: 'PICS_BEFORE',
        QUOTATION: 'QUOTATION',
        BI: 'BI',
        ACE: 'ACE',
        PICS_AFTER: 'PICS_AFTER',
        INVOICE: 'INVOICE',
        CONSULTATION: 'CONSULTATION'
    },
    CANCELED_VALIDATION_REASON: {
        INDISTINCT: 'INDISTINCT',
        WRONG_CATEGORY: 'WRONG_CATEGORY',
        UNEXPECTED: 'UNEXPECTED'
    },
    VOUCHER: {
        INTERVENTION: 8,
        ORDER: 3,
        CONSULTATION: 9
    },
    UPLOAD_DOCUMENT_TYPES: {
        PICS_BEFORE: 1,
        QUOTATION: 2,
        BI: 3,
        CONFORMITY_CERTIFICATE: 4,
        PICS_AFTER: 5,
        INVOICE: 6,
        SST_IBAN: 10,
        SST_KBIS: 11,
        SST_URSSAFF: 12,
        SST_INSURANCE: 13
    },
    ACTIONS: {
        UPLOAD: 'upload',
        DOWNLOAD: 'download'
    },
    SELECT_BOX_CONDITIONS: {
        PICTURES: 10
    },
    UPLOAD_DOCUMENT_KEYS: {
        SST_IBAN_KEY: 'iban_document',
        SST_KBIS_KEY: 'kbis_document',
        SST_URSSAFF_KEY: 'urssaff_document',
        SST_INSURANCE_KEY: 'insurance_document'
    }
};
