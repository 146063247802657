// Auth Repository

const resource = '/auth';

export default http => ({
    login(username, password) {
        return http.post(`${resource}/login`, { username, password });
    },

    logout() {
        return http.get(`${resource}/logout`);
    },

    impersonate(uuid) {
        return http.get(`${resource}/impersonate/` + uuid);
    },

    forgotPassword(email) {
        return http.post(`${resource}/password/forgot`, { email });
    },

    refreshToken(refresh_token) {
        return http.post(`${resource}/refresh_token`, { refresh_token });
    },

    resetPasswordRequest(email) {
        return http.post(`reset_password/create`, { email });
    },

    checkResetPasswordToken(token) {
        return http.get(`/reset_password/find/` + token);
    },

    resetPasword(data) {
        return http.post(`reset_password/reset`, data);
    },

    changePassword(old_password, new_password) {
        return http.post(`${resource}/change_password`, { old_password, new_password });
    }
});
