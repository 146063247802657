const resource = '/guest';

export default http => ({
    getPurchase(id) {
        return http.get(`${resource}/purchases/${id}`);
    },
    uploadDocument(id, formData) {
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        return http.post(`${resource}/purchases/${id}/upload`, formData, config);
    },
    uploadAccountDocument(formData) {
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        return http.post(`${resource}/account/documents/upload`, formData, config);
    }
});
