import '@babel/polyfill';
import 'mutationobserver-shim';
import Vue from 'vue';
import i18n from './i18n';

// Vitals
import App from './App.vue';
// disable for the moment serviceWorker
// import './registerServiceWorker';
import router from './router';
import store from './store';

// Extensions
import './plugins';
import './layouts';
import './filters';

// Vue Config
Vue.config.productionTip = false;

// Initialisation
new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app');
