<template>
    <div></div>
</template>

<script>
import { AUTH_IMPERSONATE } from '@/store/actions/auth';
import router from '@/router';

export default {
    name: 'Impersonate',
    data() {
        return {
            loginSuccess: true
        };
    },
    methods: {
        callApiImpersonate() {
            const uuid = this.$route.params.uuid;

            this.$store
                .dispatch(`auth/${AUTH_IMPERSONATE}`, { uuid })
                .then(() => {
                    if (this.$store.getters['auth/isClientUser']) {
                        router.push({ name: 'ClientHome' });
                    } else {
                        router.push({ name: 'SstHome' });
                    }
                })
                .catch(() => {
                    this.loginSuccess = false;
                    this.$toast.error(this.$t('auth.impersonate.errors.unauthorized'));
                    router.push('/');
                });
        }
    },
    beforeMount() {
        this.callApiImpersonate()
    }
};
</script>
