import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '@/views/auth/Login.vue';
import Impersonate from '@/views/auth/Impersonate.vue';
import ResetPassword from '@/views/auth/ResetPassword.vue';
import NotFound from '@/views/error/NotFound.vue';
import Maintenance from '@/views/error/Maintenance.vue';
import Unauthorised from '@/views/error/Unauthorised.vue';

Vue.use(VueRouter);

export default [
    {
        path: '/',
        name: 'Login',
        component: Login,
        meta: { layout: 'auth' }
    },
    {
        path: '/password_reset/:token',
        name: 'ResetPassword',
        component: ResetPassword,
        meta: { layout: 'auth' }
    },
    {
        path: '/impersonate/:uuid',
        name: 'Impersonate',
        component: Impersonate,
        meta: { layout: 'auth' }
    },
    {
        path: '/404',
        name: 'NotFound',
        component: NotFound,
        meta: { layout: 'auth' }
    },
    {
        path: '/401',
        name: 'Unauthorised',
        component: Unauthorised,
        meta: { layout: 'auth' }
    },
    {
        path: '/maintenance',
        name: 'Maintenance',
        component: Maintenance,
        meta: { layout: 'auth' }
    },
    {
        path: '*',
        redirect: '/404'
    }
];
