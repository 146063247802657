<template>
    <div class="section">
        <div class="d-flex flex-column align-items-center my-auto">
            <span class="display-1 d-block">404</span>
            <div class="mb-4 lead">{{ $t('httpErrors.404.info') }}</div>
            <b-button :to="{ name: this.routeName() }" variant="primary">
                {{ $t('httpErrors.404.button') }}
            </b-button>
        </div>
    </div>
</template>

<script>
import store from '@/store';

export default {
    name: 'NotFound',
    methods: {
        routeName() {
            if (store.getters['auth/isAuthenticated']) {
                if (store.getters['auth/isClientUser']) {
                    return 'ClientHome';
                }
                return 'SstHome';
            }
            return 'Login';
        }
    }
};
</script>
