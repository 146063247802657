<template>
    <div class="layout layout--default layout--subcontractor">
        <transition name="fade" mode="out-in" appear>
            <Header :logo="logo" :menu="menu" />
        </transition>

        <transition name="fade" mode="out-in" appear>
            <Sidebar :logo="logo" :menu="menu" />
        </transition>

        <main class="app-main" role="main">
            <div class="app-main__inner">
                <slot />
            </div>

            <transition name="fade" mode="out-in" appear>
                <Footer />
            </transition>
        </main>
    </div>
</template>

<script>
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import store from '@/store';
import _ from 'lodash';

export default {
    name: 'SubcontractorLayout',
    components: {
        Header,
        Sidebar,
        Footer
    },
    data() {
        return {
            logo: require('@/assets/logo-400.png'),
            menu: [
                {
                    href: { name: 'SstHome' },
                    title: 'Accueil',
                    icon: 'fas fa-home',
                    key: 'home'
                },
                {
                    href: { name: 'SstInterventionsListe' },
                    title: 'Interventions',
                    icon: 'fas fa-tools',
                    key: 'interventions'
                },
                {
                    title: 'Factures',
                    icon: 'far fa-clipboard',
                    key: 'invoices',
                    child: [
                        {
                            href: { name: 'SstPendingInvoice' },
                            title: 'Vos factures en cours de traitement'
                        },
                        {
                            href: { name: 'SstPaidInvoice' },
                            title: 'Vos factures payées'
                        }
                    ]
                },
                {
                    title: 'BAF / RM',
                    icon: 'far fa-clipboard',
                    key: 'baf',
                    child: [
                        {
                            href: { name: 'SstBafCurrentList' },
                            title: 'En cours'
                        },
                        {
                            href: { name: 'SstBafPreviousList' },
                            title: 'Historique'
                        }
                    ]
                },
                {
                    title: 'Profil',
                    icon: 'fas fa-user-alt',
                    key: 'profile',
                    child: [
                        {
                            href: { name: 'SstMyAccount' },
                            title: 'Gestion de votre profil'
                        }
                    ]
                }
            ]
        };
    },
    created() {
        this.hideMenuOptions();
    },
    methods: {
        hideMenuOptions() {
            if (!store.getters['context/sstHasMetalRolloutAccess']) {
                _.remove(this.menu, function(e) {
                    return e.key === 'baf';
                });
            }
        }
    }
};
</script>
