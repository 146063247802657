const resource = '/dashboard';

export default http => ({
    getMonthlyCount(global_filters) {
        return http.post(`${resource}/ir/monthly-count`, { global_filters });
    },
    getTopSites(global_filters) {
        return http.post(`${resource}/ir/top-sites`, { global_filters });
    },
    getCostsBySkill(filters) {
        return http.post(`${resource}/costs-by-skills`, filters);
    },
    getCountsBySkill(filters) {
        return http.post(`${resource}/counts-by-skills`, filters);
    },
    getTimeSynthesis(filters) {
        return http.post(`${resource}/time-synthesis`, filters);
    },
    getAmountSynthesis(filters) {
        return http.post(`${resource}/count-vs-amount-synthesis`, filters);
    },
    getPerformanceIndicators(filters) {
        return http.post(`${resource}/clients-kpi`, filters);
    },
    getClientPerformanceIndicators(filters) {
        return http.post(`${resource}/clients-performance-kpi`, filters);
    }
});
