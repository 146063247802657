<template>
    <div class="section">
        <div class="auth">
            <div class="card auth-card">
                <img alt="Phinelec" class="d-block mx-auto" src="../../assets/logo-400.png" width="100" />

                <p class="my-3 font-weight-bold text-center">{{ $t('auth.login.info.accessToYourSpace') }}</p>
                <p class="error auth-error" v-if="!loginSuccess">
                    {{ $t('auth.login.errors.inactive') }}
                </p>
                <Validation-observer ref="observer" v-slot="{ handleSubmit }">
                    <b-form @submit.prevent="handleSubmit(onSubmit)">
                        <Validation-provider :name="$t('auth.login.labels.email')" :rules="{ required: true }" v-slot="validationContext">
                            <b-form-group>
                                <b-form-input
                                    id="email"
                                    type="text"
                                    v-model="form.username"
                                    :state="getValidationState(validationContext)"
                                    :placeholder="$t('auth.login.labels.email')"
                                ></b-form-input>

                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </Validation-provider>

                        <Validation-provider name="Mot de passe" :rules="{ required: true }" v-slot="validationContext">
                            <b-form-group>
                                <b-form-input
                                    id="password"
                                    type="password"
                                    v-model="form.password"
                                    :state="getValidationState(validationContext)"
                                    :placeholder="$t('auth.login.labels.password')"
                                ></b-form-input>

                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </Validation-provider>

                        <b-form-group class="text-center">
                            <b-form-checkbox id="rememberMe" name="rememberMe" unchecked-value="no" v-model="form.rememberMe" value="yes">
                                {{ $t('auth.login.info.rememberMe') }}
                            </b-form-checkbox>
                        </b-form-group>

                        <b-button type="submit" variant="danger" block>{{ $t('auth.login.buttons.send') }}</b-button>
                    </b-form>
                </Validation-observer>
            </div>

            <div class="auth-meta py-2 text-center">
                <b-link @click="$bvModal.show('reset-password-modal')">
                    {{ $t('auth.login.info.forgotPassword') }}
                </b-link>
            </div>
        </div>
        <reset-password-request />
    </div>
</template>

<script>
import { AUTH_LOGIN } from '@/store/actions/auth';
import router from '@/router';
import ResetPasswordRequest from '@/components/auth/ResetPasswordRequest';

export default {
    name: 'Login',
    components: {
        ResetPasswordRequest
    },
    data() {
        return {
            form: {
                username: null,
                password: null,
                rememberMe: 'no'
            },
            loginSuccess: true
        };
    },
    methods: {
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        onSubmit() {
            const { username, password } = this.form;
            this.$store
                .dispatch(`auth/${AUTH_LOGIN}`, { username, password })
                .then(() => {
                    if (this.$store.getters['auth/isClientUser']) {
                        router.push({ name: 'ClientHome' });
                    } else {
                        router.push({ name: 'SstHome' });
                    }
                })
                .catch(() => {
                    this.loginSuccess = false;
                });
        }
    }
};
</script>

<style scoped lang="scss">
.auth {
    max-width: 340px;
    margin: 0 auto;

    &-card {
        padding: $spacer * 2.5;
    }

    &-meta {
        a {
            font-size: 14px;
            text-decoration: underline;
        }
    }

    &-error {
        padding: 5px;
        background: red;
        color: #ffffff;
        text-align: center;
        font-size: 14px;
    }
}
</style>
