<template>
    <div class="section">
        <div class="auth">
            <div class="card auth-card">
                <img alt="Phinelec" class="d-block mx-auto" src="../../assets/logo-400.png" width="220" />
                <p class="my-3 font-weight-bold text-center">{{ $t('auth.resetPassword.title') }}</p>
                <p class="error auth-error" v-if="!loginSuccess">
                    {{ $t('auth.login.errors.inactive') }}
                </p>
                <Validation-observer class="my-3" ref="observer" v-slot="{ handleSubmit }">
                    <b-form @submit.prevent="handleSubmit(onSubmit)">
                        <Validation-provider
                            :name="$t('auth.resetPassword.labels.email')"
                            :rules="{ required: true, email: true }"
                            v-slot="validationContext"
                        >
                            <b-form-group>
                                <b-form-input
                                    id="email"
                                    type="text"
                                    v-model="form.email"
                                    :state="getValidationState(validationContext)"
                                    :placeholder="$t('auth.resetPassword.labels.email')"
                                ></b-form-input>

                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </Validation-provider>
                        <Validation-provider
                            :name="$t('auth.resetPassword.labels.new_password')"
                            :rules="{ required: true, min: 8, regex: regex.PASSWORD }"
                            vid="password"
                            v-slot="validationContext"
                        >
                            <b-form-group>
                                <b-form-input
                                    id="password"
                                    type="password"
                                    v-model="form.username"
                                    :state="getValidationState(validationContext)"
                                    :placeholder="$t('auth.resetPassword.labels.new_password')"
                                ></b-form-input>

                                <b-form-invalid-feedback v-if="validationContext">
                                    {{ passwordErrorMessage(validationContext) }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </Validation-provider>

                        <Validation-provider
                            :name="$t('auth.resetPassword.labels.repeat_password')"
                            rules="required|confirmed:password"
                            v-slot="validationContext"
                        >
                            <b-form-group>
                                <b-form-input
                                    id="confirmPassword"
                                    type="password"
                                    v-model="form.password"
                                    :state="getValidationState(validationContext)"
                                    :placeholder="$t('auth.resetPassword.labels.repeat_password')"
                                ></b-form-input>

                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </Validation-provider>

                        <b-button type="submit" variant="danger" block>{{ $t('auth.login.buttons.send') }}</b-button>
                    </b-form>
                </Validation-observer>
            </div>
        </div>
    </div>
</template>

<script>
import router from '@/router';
import { AuthRepository } from '@/repositories';
import { REGEX } from '@/constants';

export default {
    name: 'ResetPassword',
    data() {
        return {
            form: {
                email: null,
                password: null
            },
            loginSuccess: true,
            regex: REGEX
        };
    },
    created() {
        this.checkTokenValidity();
    },
    methods: {
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        onSubmit() {
            const postData = {
                email: this.form.email,
                password: this.form.password,
                password_confirmation: this.form.password,
                password_token: this.$route.params.token
            };
            AuthRepository.resetPasword(postData)
                .then(() => {
                    router.push('/');
                    this.$toast.success(this.$i18n.t('auth.resetPassword.notification.success'));
                })
                .catch(() => {
                    this.$toast.error(this.$i18n.t('auth.resetPassword.notification.error'));
                });
        },
        checkTokenValidity() {
            AuthRepository.checkResetPasswordToken(this.$route.params.token)
                .then(() => {})
                .catch(error => {
                    this.$toast.error(error.response.data.message);
                    router.push('/');
                });
        },
        passwordErrorMessage({ failedRules }) {
            if ('regex' in failedRules) {
                return this.$t('auth.login.form.email.errors.regex');
            }
            return failedRules[Object.keys(failedRules)[0]];
        }
    }
};
</script>

<style scoped lang="scss">
.auth {
    max-width: 340px;
    margin: 0 auto;

    &-card {
        padding: $spacer * 2.5;
    }

    &-meta {
        a {
            font-size: 14px;
            text-decoration: underline;
        }
    }

    &-error {
        padding: 5px;
        background: red;
        color: #ffffff;
        text-align: center;
        font-size: 14px;
    }
}
</style>
