const resource = '/intervention_requests';

export default http => ({
    getInterventionList(filters) {
        return http.post(`${resource}`, filters);
    },
    getInterventionDocuments(id) {
        return http.get(`${resource}/${id}/documents`);
    },
    getInterventionComments(id) {
        return http.get(`${resource}/${id}/comments`);
    },
    newInterventionRequest(formData) {
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        return http.post(`${resource}/new/request`, formData, config);
    }
});
