<template>
    <div class="app-sidebar">
        <perfect-scrollbar v-once :options="scrollbarSettings">
            <div class="app-header__logo" v-if="logo">
                <b-link class="d-block mx-auto" to="/client/accueil">
                    <img :src="logo" alt="Phinelec.fr" height="60" />
                </b-link>
            </div>
            <div class="app-sidebar-content">
                <sidebar-menu showChild hideToggle relative disableHover :menu="menu" @item-click="onItemClick">
                    <!--
                        <span slot="dropdown-icon">
                            <i class="fa fa-angle-right" />
                        </span>
                    -->
                </sidebar-menu>
            </div>
        </perfect-scrollbar>
    </div>
</template>

<script>
import { SidebarMenu } from 'vue-sidebar-menu';
import { SIDEBAR_TOGGLE } from '@/store/actions/sidebar';

export default {
    name: 'Sidebar',
    components: {
        SidebarMenu
    },
    props: {
        logo: String,
        menu: Array
    },
    data() {
        return {
            scrollbarSettings: {
                wheelPropagation: false
            }
        };
    },
    methods: {
        onItemClick() {
            const el = document.body;
            el.classList['remove']('sidebar-open');
            this.$store.dispatch(`sidebar/${SIDEBAR_TOGGLE}`, false);
        }
    }
};
</script>

<style scoped lang="scss">
.app-sidebar {
    display: flex;
    position: fixed;
    width: $layout-sidebar-width;
    height: 100vh;
    z-index: $layout-sidebar-zIndex;
    overflow: hidden;
    flex-direction: column;
    transition: all 0.3s ease;
    background-color: $white;
    border-right: 1px solid gray('300');
    transform: translateX(-100%);
    transition: all 0.3s ease;

    .sidebar-open & {
        transform: translateX(0);
    }

    .app-header__logo {
        padding: $spacer;
        width: 100%;
        display: flex;
        align-items: center;
        transition: width 0.2s;
        flex-shrink: 0;
        border-bottom: 1px solid gray('300');
    }

    .app-sidebar-content {
        flex-grow: 1;
        display: flex;
        width: 100%;

        .app-sidebar-scroll {
            width: 100%;
        }
    }
}
</style>
