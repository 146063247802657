import { RADIO_BUTTON_YEAR_CHANGE, SELECT_BOX_PERIOD_CHANGE } from '@/store/actions/periodFilter';
import { COST, RADIO_BUTTONS_KEYS } from '@/constants/client/cost';
import { RESET } from '@/store/actions/commonActions';
import moment from 'moment';

// Initial State
const initialState = () => ({
    // default values
    radioButtonYear: {
        // by default, period filter is "Année roulante"
        selected: COST.RADIO_BUTTONS.OPTIONS[0].value
    },
    selectBox: {
        startDate: moment()
            .subtract(1, 'years')
            .format('MM/YYYY'),
        endDate: moment()
            .endOf('month')
            .format('MM/YYYY')
    },
    startDate: moment()
        .subtract(1, 'years')
        .format('01/MM/YYYY'),
    endDate: moment()
        .endOf('month')
        .format('DD/MM/YYYY')
});

// State
const state = initialState();

// Actions
const actions = {
    [RADIO_BUTTON_YEAR_CHANGE]: ({ commit }, newValue) => {
        commit(RADIO_BUTTON_YEAR_CHANGE, { newValue });
    },
    [SELECT_BOX_PERIOD_CHANGE]: ({ commit }, { $event, id }) => {
        commit(SELECT_BOX_PERIOD_CHANGE, { $event, id });
    },
    [RESET]: ({ commit }) => {
        commit(RESET);
    }
};

// Mutations
const mutations = {
    [RADIO_BUTTON_YEAR_CHANGE]: (state, { newValue }) => {
        state.radioButtonYear.selected = newValue;
        switch (newValue) {
            case RADIO_BUTTONS_KEYS.ROLLING_YEAR:
                state.startDate = moment()
                    .subtract(1, 'years')
                    .format('01/MM/YYYY');
                state.endDate = moment()
                    .endOf('month')
                    .format('DD/MM/YYYY');
                break;
            case RADIO_BUTTONS_KEYS.CURRENT_YEAR:
                state.startDate = moment()
                    .startOf('month')
                    .format('01/01/YYYY');
                state.endDate = moment()
                    .endOf('month')
                    .format('DD/MM/YYYY');
                break;
            case RADIO_BUTTONS_KEYS.DYNAMIC_PERIOD:
                state.startDate = '01/' + state.selectBox.startDate;
                state.endDate = moment(state.selectBox.endDate, 'MM/YYYY')
                    .endOf('month')
                    .format('DD/MM/YYYY');
                break;
        }
    },
    [SELECT_BOX_PERIOD_CHANGE]: (state, { $event, id }) => {
        if (id === 'startDate') {
            state.selectBox.startDate = $event;
            state.startDate = '01/' + $event;
        } else {
            state.selectBox.endDate = $event;
            state.endDate = moment($event, 'MM/YYYY')
                .endOf('month')
                .format('DD/MM/YYYY');
        }
    },
    [RESET]: state => {
        Object.assign(state, initialState());
    }
};

// Getters
const getters = {
    getSelectedRadioButton: state => state.radioButtonYear.selected,
    getSelectBoxStartDate: state => state.selectBox.startDate,
    getSelectBoxEndDate: state => state.selectBox.endDate,
    getStartAndEndDateFilters: state => {
        return {
            period: {
                from: state.startDate,
                to: state.endDate
            }
        };
    },
    getStartDate: state => {
        return state.startDate;
    },
    getEndDate: state => {
        return state.endDate;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
