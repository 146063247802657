<template>
    <footer class="app-footer">
        <div class="app-footer__inner text-center">
            © Copyright {{ new Date().getFullYear() }} &nbsp;
            <a href="http://phinelec.fr">Phinelec.fr</a>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer'
};
</script>

<style scoped lang="scss">
.app-footer {
    &__inner {
        padding: $grid-gutter-width;
        padding-top: 0;
        font-size: 12px;
        color: gray('500');

        a:not(:hover) {
            color: inherit;
        }
    }
}
</style>
