<template>
    <b-navbar class="app-header" type="light" :sticky="true">
        <b-container class="px-0">
            <b-navbar-brand :to="{ name: homeUrl() }">
                <img :src="logo" alt="Phinelec.fr" />
            </b-navbar-brand>

            <b-navbar-nav class="mr-auto">
                <burgher></burgher>
            </b-navbar-nav>
            <b-navbar-nav class="ml-auto navbar-menu">
                <template v-for="item in menu">
                    <b-nav-item v-if="item.href" :key="item.title" :to="item.href">
                        {{ item.title }}
                    </b-nav-item>

                    <b-nav-item-dropdown v-else :key="item.title" :text="item.title" left :toggle-class="addExtraClass(item)">
                        <b-dropdown-item v-for="subItem in item.child" :key="subItem.title" :to="subItem.href">
                            {{ subItem.title }}
                        </b-dropdown-item>
                    </b-nav-item-dropdown>
                </template>
            </b-navbar-nav>

            <b-navbar-nav class="ml-3">
                <b-button size="sm" variant="danger" @click="logout">Deconnexion</b-button>
            </b-navbar-nav>
        </b-container>

        <div class="divider"></div>

        <b-container class="px-0 py-2" v-if="$store.getters['auth/isClientUser']">
            <header-multiselect class="client-select"></header-multiselect>
        </b-container>
    </b-navbar>
</template>

<script>
import { mapActions } from 'vuex';
import { AUTH_LOGOUT } from '@/store/actions/auth';
import burgher from './Burger';
import HeaderMultiselect from './HeaderMultiselect';

export default {
    name: 'Header',
    props: {
        logo: String,
        menu: Array
    },
    components: {
        burgher,
        HeaderMultiselect
    },
    methods: {
        ...mapActions('auth', {
            logout: AUTH_LOGOUT
        }),
        addExtraClass(item) {
            const routes = item.child.map(route => {
                let routeParams = this.$router.resolve({
                    name: route.href.name
                });
                return `${routeParams.href}`;
            });

            if (new RegExp(routes.join('|')).test(this.$route.path)) {
                return 'dropdown-active';
            }
            return '';
        },
        homeUrl() {
            if (this.$store.getters['auth/isAuthenticated']) {
                if (this.$store.getters['auth/isClientUser']) {
                    return 'ClientHome';
                }
                return 'SstHome';
            }
            return 'Login';
        }
    }
};
</script>

<style scoped lang="scss">
.app-header {
    width: 100%;
    flex-direction: column;
    padding: 0;
    background-color: $white;
    z-index: $layout-header-zIndex;
    transition: all 0.2s;
    border-bottom: 1px solid rgba(gray('300'), 0.5);

    .client-select {
        flex-grow: 1;
        max-width: 780px;
        height: 34px;
    }

    .divider {
        width: 100%;
        height: 0;
        border-bottom: 1px solid rgba(gray('300'), 0.5);
    }

    .navbar-brand {
        img {
            height: 40px;
            width: auto;
        }
    }

    .navbar-nav {
        .nav-item {
            .router-link-active {
                color: #cc0808;
                border-bottom: 2px solid #cc0808;
            }
        }
    }

    .nav-link:hover {
        background: rgba(17, 17, 17, 0.04);
    }

    ::v-deep {
        .nav-link {
            border-style: solid;
            border-width: 2px 0;
            border-color: transparent;
            padding: 12px 16px;
            font-size: 14px;

            &:hover {
                background: rgba(17, 17, 17, 0.04);
            }
        }

        .router-link-active {
            color: #cc0808;
        }

        .dropdown-active {
            color: #cc0808;
            border-bottom: 2px solid #cc0808;
        }

        .dropdown-menu {
            border-radius: 3px;
            box-shadow: 0 8px 24px rgba(17, 17, 17, 0.24);
            border: none;
            margin-top: 0;
        }

        .dropdown-item {
            padding: 6px 16px;
            font-size: 14px;
        }
    }

    .dropdown.show {
        ::v-deep .nav-link {
            border-bottom-color: $primary;
        }
    }

    @include media-breakpoint-down(md) {
        padding-left: 0 !important;
        z-index: $layout-sidebar-zIndex + $layout-header-zIndex;
        box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03), 0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
            0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05), 0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
    }
}
@media screen and (max-width: 779px) {
    .app-header {
        .navbar-menu {
            display: none;
        }
        .navbar-brand {
            display: none;
        }
    }
}
</style>
