export const INVOICE = {
    PENDING: {
        RADIO_BUTTONS_KEYS: {
            ALL: '1',
            VALIDATED: '2',
            IN_PROGRESS: '3',
            BLOCKED: '4'
        }
    }
};
