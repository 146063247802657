import Vue from 'vue';
import VueRouter from 'vue-router';
import EmptyRouterView from '@/layouts/EmptyRouterView';
import store from '@/store';

Vue.use(VueRouter);

const ifAuthenticated = (_to, _from, next) => {
    if (store.getters['auth/isAuthenticated'] && !store.getters['auth/isClientUser']) {
        next();
        return;
    }

    next('/401');
};

export default [
    {
        path: '/sst',
        beforeEnter: ifAuthenticated,
        component: EmptyRouterView,
        children: [
            {
                path: 'accueil',
                name: 'SstHome',
                component: () => import('../views/sst/Home')
            },
            {
                path: 'interventions',
                beforeEnter: ifAuthenticated,
                component: EmptyRouterView,
                children: [
                    {
                        path: '',
                        name: 'SstInterventionsListe',
                        component: () => import('../views/sst/intervention/InterventionListe')
                    },
                    {
                        path: ':id',
                        name: 'SstInterventionsShow',
                        component: () => import('../views/sst/intervention/InterventionShow')
                    }
                ]
            },
            {
                path: 'factures',
                beforeEnter: ifAuthenticated,
                component: EmptyRouterView,
                children: [
                    {
                        path: 'payee',
                        name: 'SstPaidInvoice',
                        component: () => import('../views/sst/invoice/Paid')
                    },
                    {
                        path: 'encours',
                        name: 'SstPendingInvoice',
                        component: () => import('../views/sst/invoice/Pending')
                    }
                ]
            },
            {
                path: 'profile',
                beforeEnter: ifAuthenticated,
                component: EmptyRouterView,
                children: [
                    {
                        path: 'my-account',
                        name: 'SstMyAccount',
                        component: () => import('../views/sst/profile/MyAccount')
                    }
                ]
            },
            {
                path: 'site',
                beforeEnter: ifAuthenticated,
                component: EmptyRouterView,
                children: [
                    {
                        path: 'metal-rollouts',
                        name: 'SstSiteList',
                        component: () => import('../views/sst/site/List')
                    }
                ]
            },
            {
                path: 'baf',
                beforeEnter: ifAuthenticated,
                component: EmptyRouterView,
                children: [
                    {
                        path: 'current',
                        name: 'SstBafCurrentList',
                        component: () => import('../views/sst/baf/Current')
                    },
                    {
                        path: 'previous',
                        name: 'SstBafPreviousList',
                        component: () => import('../views/sst/baf/Previous')
                    },
                    {
                        path: ':id',
                        name: 'SstBafOnePrevious',
                        component: () => import('../views/sst/baf/OnePrevious'),
                        meta: { layout: 'pdf' }
                    }
                ]
            }
        ]
    }
];
