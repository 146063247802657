import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);
import clientRoutes from '@/router/clientRoutes';
import sstRoutes from '@/router/sstRoutes';
import guestSstRoutes from '@/router/guestSstRoutes';
import commonRoutes from '@/router/commonRoutes';

const router = new VueRouter({
    mode: 'history',
    routes: [...clientRoutes, ...sstRoutes, ...commonRoutes, ...guestSstRoutes]
});

export default router;
