import { SIDEBAR_TOGGLE } from '@/store/actions/sidebar';

// Initial State
const initialState = () => ({
    isToggle: false
});

// State
const state = initialState();

// Actions
const actions = {
    [SIDEBAR_TOGGLE]: ({ commit }, isToggle) => {
        commit(SIDEBAR_TOGGLE, isToggle);
    }
};

// Mutations
const mutations = {
    [SIDEBAR_TOGGLE]: (state, isToggle) => {
        state.isToggle = isToggle;
    }
};

// Getters
const getters = {
    isToggle: state => state.isToggle
};

export default {
    state,
    namespaced: true,
    getters,
    actions,
    mutations
};
