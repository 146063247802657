<template>
    <div class="section">
        <div class="d-flex flex-column align-items-center my-auto">
            <img class="d-block mx-auto" src="../../assets/maintenance.png" alt="Phinelec" />
            <div class="mb-4 mt-4 lead">{{ $t('httpErrors.503.p1') }}</div>
            <div class="mb-4 lead">{{ $t('httpErrors.503.p2') }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Maintenance'
};
</script>
