<template>
    <div class="section">
        <div class="d-flex flex-column align-items-center my-auto">
            <span class="display-1 d-block">401</span>
            <div class="mb-4 lead">{{ $t('httpErrors.401.info') }}</div>
            <b-button to="/dashboard" variant="primary">{{ $t('httpErrors.401.button') }}</b-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Unauthorised'
};
</script>
