<template>
    <div class="layout layout--auth">
        <div class="container">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'AuthLayout'
};
</script>

<style scoped lang="scss">
.layout--auth {
    background: linear-gradient(to top, $gray-300 50%, $white 100%);
    padding: $grid-gutter-width;
    justify-content: center;
}
</style>
