import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_IMPERSONATE, AUTH_REFRESH_TOKEN } from '@/store/actions/auth';
import router from '@/router';
import { USER_TYPE } from '@/constants';
import { USER_CONTEXT } from '@/store/actions/context';
import { RESET } from '@/store/actions/commonActions';
import store from '../../store';
import { AuthRepository, ContextRepository } from '@/repositories';
import { SET_GUEST_USER } from "../actions/auth";

// Initial State
const initialState = () => ({
    token: '',
    type: USER_TYPE.Subcontractor,
    refreshToken: ''
});

// State
const state = initialState();

// Actions
const actions = {
    [AUTH_LOGIN]: ({ commit }, { username, password }) => {
        return new Promise((resolve, reject) => {
            AuthRepository.login(username, password)
                .then(response => {
                    commit(AUTH_LOGIN, { token: response.data.data.access_token });
                    ContextRepository.getContexts().then(context => {
                        commit(AUTH_LOGIN, {
                            token: response.data.data.access_token,
                            type: context.data.data[0].type === USER_TYPE.Client ? USER_TYPE.Client : USER_TYPE.Subcontractor,
                            refreshToken: response.data.data.refresh_token
                        });
                        store.dispatch(`context/${USER_CONTEXT}`, context.data.data);
                        resolve(response);
                    });
                }, error => {
                    reject(error);
                })
                .catch(error => {
                    console.log(error);
                    sessionStorage.clear();
                    reject(error);
                });
        });
    },

    [AUTH_LOGOUT]: ({ commit }) => {
        commit(AUTH_LOGOUT);
        router.push('/');
        sessionStorage.clear();
        localStorage.clear();
    },

    [AUTH_IMPERSONATE]: ({ commit }, { uuid }) => {
        return new Promise((resolve, reject) => {
            AuthRepository.impersonate(uuid)
                .then(response => {
                    commit(AUTH_LOGIN, {token: response.data.data.access_token});
                    ContextRepository.getContexts().then(context => {
                        commit(AUTH_LOGIN, {
                            token: response.data.data.access_token,
                            type: context.data.data[0].type === USER_TYPE.Client ? USER_TYPE.Client : USER_TYPE.Subcontractor,
                            refreshToken: response.data.data.refresh_token
                        });
                        store.dispatch(`context/${USER_CONTEXT}`, context.data.data);
                        resolve(response);
                    });

                }, error => {
                    reject(error);
                })
                .catch(error => {
                    console.log(error);
                    sessionStorage.clear();
                    reject(error);
                });
        });
    },

    [AUTH_REFRESH_TOKEN]: ({ commit, state }) => {
        return new Promise((resolve, reject) => {
            AuthRepository.refreshToken(state.refreshToken)
                .then(response => {
                    commit(AUTH_REFRESH_TOKEN, {
                        token: response.data.data.access_token,
                        refreshToken: response.data.data.refresh_token
                    });
                    resolve(response);
                })
                .catch(error => {
                    console.log(error);
                    sessionStorage.clear();
                    reject(error);
                });
        });
    },

    [SET_GUEST_USER]: () => {
        localStorage.clear();
        store.replaceState({
            auth: {
                token: '',
                type: USER_TYPE.GuestSubcontractor,
                refreshToken: ''
            }
        });
    }
};

// Mutations
const mutations = {
    [AUTH_LOGIN]: (state, { token, type, refreshToken }) => {
        state.token = token;
        state.refreshToken = refreshToken;
        if (type) {
            state.type = type;
        }
    },
    [AUTH_LOGOUT]: state => {
        state.token = '';
        state.refreshToken = '';
        state.type = USER_TYPE.Subcontractor;
        store.dispatch(RESET, {}, { root: true });
    },
    [AUTH_IMPERSONATE]: (state, { token, type, refreshToken }) => {
        state.token = token;
        state.refreshToken = refreshToken;
        if (type) {
            state.type = type;
        }
    },
    [AUTH_REFRESH_TOKEN]: (state, { token, refreshToken }) => {
        state.token = token;
        state.refreshToken = refreshToken;
    }
};

// Getters
const getters = {
    isAuthenticated: state => !!state.token,
    authUserType: state => state.type,
    getUserToken: state => state.token,
    getRefreshToken: state => state.refreshToken,
    isClientUser: state => {
        return state.type === USER_TYPE.Client;
    },
    isGuestSstUser: state => {
        return state.type === USER_TYPE.GuestSubcontractor;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
