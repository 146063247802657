<template>
    <div class="layout layout--default layout--client">
        <transition name="fade" mode="out-in" appear>
            <Header :logo="logo" :menu="menu" />
        </transition>

        <transition name="fade" mode="out-in" appear>
            <Sidebar :logo="logo" :menu="menu" />
        </transition>

        <main class="app-main" role="main">
            <div class="app-main__inner">
                <slot />
            </div>

            <transition name="fade" mode="out-in" appear>
                <Footer />
            </transition>
        </main>
    </div>
</template>

<script>
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import store from '@/store';
import _ from 'lodash';

export default {
    name: 'ClientLayout',
    components: {
        Header,
        Sidebar,
        Footer
    },
    data() {
        return {
            logo: require('@/assets/logo-400.png'),
            menu: [
                {
                    href: { name: 'ClientHome' },
                    title: 'Accueil',
                    icon: 'fas fa-home',
                    key: 'home'
                },
                {
                    title: 'Dashboard',
                    icon: 'fas fa-chart-line',
                    key: 'dashboard',
                    child: [
                        {
                            href: { name: 'ClientPerformanceIndicators' },
                            title: 'Indicateurs de performance'
                        },
                        {
                            href: { name: 'ClientDistributionByValue' },
                            title: 'Par cout'
                        },
                        {
                            href: { name: 'ClientDistributionByPercentage' },
                            title: 'Par pourcentage'
                        }
                    ]
                },
                {
                    href: { name: 'ClientInterventionList' },
                    title: 'Interventions',
                    icon: 'far fa-file-alt',
                    key: 'profile'
                },
                {
                    title: 'Devis',
                    icon: 'far fa-clipboard',
                    child: [
                        {
                            href: { name: 'ClientPendingQuote' },
                            title: 'En attente'
                        },
                        {
                            href: { name: 'ClientTreatedQuote' },
                            title: 'Traités (via le site) '
                        }
                    ]
                },
                {
                    href: { name: 'ClientPendingInvoice' },
                    title: 'Factures',
                    icon: 'far fa-file-alt'
                },
                {
                    href: { name: 'ClientInterventionMetalRollouts' },
                    title: 'Campagnes RM',
                    icon: 'far fa-list-alt'
                },
                {
                    href: { name: 'ClientMyAccount' },
                    title: 'Profil',
                    icon: 'fas fa-user-alt'
                }
            ]
        };
    },
    created() {
        this.hideMenuOptions();
    },
    methods: {
        hideMenuOptions() {
            if (store.getters['context/clientHasMetalRolloutCampaign']) {
                _.remove(this.menu, function(e) {
                    return e.key === 'campaigns';
                });
            }
        }
    }
};
</script>
