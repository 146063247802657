<template>
    <div id="app">
        <component :is="layout">
            <router-view />
        </component>
    </div>
</template>

<script>
export default {
    computed: {
        layout() {
            return (this.$route.meta.layout || this.$store.getters['auth/authUserType']) + '-layout';
        }
    }
};
</script>

<style lang="scss">
@import '@/assets/scss/index.scss';
</style>
