<template>
    <div class="layout">
        <div class="container">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'PdfLayout'
};
</script>

<style scoped></style>
