import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { RESET } from '@/store/actions/commonActions';

// Vuex Modules
import auth from './modules/auth';
import context from './modules/context';
import periodFilter from './modules/periodFilter';
import sidebar from '@/store/modules/sidebar';
import interventionTableState from '@/store/modules/interventionTableState';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
    modules: {
        auth,
        context,
        periodFilter,
        sidebar,
        interventionTableState
    },
    strict: debug,
    plugins: [createPersistedState()],
    actions: {
        // implement reset actions in modules where you want to reset data and add the call here
        reset({ commit }) {
            commit('periodFilter/' + RESET);
            commit('interventionTableState/' + RESET);
        }
    }
});
