<!-- Vue component -->
<template>
    <b-row>
        <b-col col md="5">
            <multiselect
                :id="context.Client"
                v-model="value.client"
                label="name"
                track-by="name"
                :options="options.client"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :placeholder="$t('client.components.headerMultiselect.client.labels.chooseClient')"
                :select-label="''"
                :deselect-label="''"
                :selectedLabel="$t('client.components.headerMultiselect.client.labels.selected')"
                :closeOnSelect="false"
                @select="select"
                @remove="remove"
                @input="change"
                @close="close"
            >
                <template slot="tag">{{ '' }}</template>
                <template slot="selection" slot-scope="{ values, search, isOpen }">
                    <span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">
                        <span v-if="values.length === 1">
                            <!-- {{ values.length }} {{ $t('headerMultiselect.client.labels.nbClientSelected') }}-->
                            {{ values[0].name }}
                        </span>
                        <span v-if="values.length > 1">
                            {{ values.length }} {{ $t('client.components.headerMultiselect.client.labels.nbsClientSelected') }}
                        </span>
                    </span>
                </template>
                <template slot="noResult">
                    <span>
                        {{ $t('client.components.headerMultiselect.client.labels.noResults') }}
                    </span>
                </template>
            </multiselect>
        </b-col>
        <b-col col md="5">
            <multiselect
                :id="context.Site"
                v-model="value.site"
                label="name"
                track-by="name"
                :options="options.site"
                :multiple="false"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :placeholder="$t('client.components.headerMultiselect.site.labels.chooseSite')"
                :select-label="''"
                :deselect-label="''"
                :closeOnSelect="true"
                @select="select"
                @remove="remove"
                @input="change"
                @close="close"
            >
                <template slot="noOptions">
                    <span>
                        {{ $t('client.components.headerMultiselect.site.labels.noOptions') }}
                    </span>
                </template>
                <template slot="noResult">
                    <span>
                        {{ $t('client.components.headerMultiselect.site.labels.noResults') }}
                    </span>
                </template>
            </multiselect>
        </b-col>
        <b-col col md="2" v-if="displayResetIcon()">
            <b-button
                class="multiselect-filter-button"
                size="sm"
                variant="link"
                @click="resetFilters"
                :title="$t('client.components.headerMultiselect.reset.tooltip')"
            >
                <b-iconstack font-scale="2" class="pointer-event">
                    <b-icon stacked icon="funnel" variant="danger" scale="0.75"></b-icon>
                    <b-icon stacked icon="slash-circle" variant="danger"></b-icon>
                </b-iconstack>
            </b-button>
        </b-col>
    </b-row>
</template>

<script>
import store from '../../store';
import {
    CLOSE_SELECTBOX,
    INPUT_HEADER_CHANGED,
    RESET_FILTER,
    UPDATE_SELECTED_STATE,
    UPDATE_SELECTED_STATE_FOR_SITES
} from '@/store/actions/context';
import { CONTEXT } from '@/constants';

export default {
    data() {
        return {
            value: {
                [CONTEXT.Client]: store.getters['context/getSelectedClients'],
                [CONTEXT.Site]: store.getters['context/getSelectedSite']
            },
            options: {
                [CONTEXT.Client]: store.getters['context/getData'],
                [CONTEXT.Site]: store.getters['context/getSites']
            },
            context: CONTEXT,
            selectedClient: this.$store.getters['context/getGlobalFiltersIds'].clients,
            selectedSite: this.$store.getters['context/getGlobalFiltersIds'].site
        };
    },
    methods: {
        select(selectedOption, id) {
            store.dispatch(`context/${UPDATE_SELECTED_STATE}`, [selectedOption, true, id]);
        },
        remove(removedOption, id) {
            store.dispatch(`context/${UPDATE_SELECTED_STATE}`, [removedOption, false, id]);
        },
        change(value, id) {
            if (id === this.context.Client) {
                store.dispatch(`context/${UPDATE_SELECTED_STATE_FOR_SITES}`, [value]);
            }
            this.getValue();
            this.getOptions();
        },
        close() {
            store.dispatch(`context/${CLOSE_SELECTBOX}`);
            if (
                !this.equal(this.selectedClient, this.$store.getters['context/getGlobalFiltersIds'].clients) ||
                this.selectedSite !== this.$store.getters['context/getGlobalFiltersIds'].site
            ) {
                this.selectedClient = this.$store.getters['context/getGlobalFiltersIds'].clients;
                this.selectedSite = this.$store.getters['context/getGlobalFiltersIds'].site;
                store.dispatch(`context/${INPUT_HEADER_CHANGED}`);
            }
            this.getValue();
            this.getOptions();
        },
        displayResetIcon() {
            return (
                store.getters['context/getClients'].length !== store.getters['context/getSelectedClients'].length ||
                store.getters['context/getSelectedSite'].length !== 0
            );
        },
        resetFilters() {
            store.dispatch(`context/${RESET_FILTER}`);
            this.getValue();
            this.getOptions();
        },
        getValue() {
            this.value = {
                [CONTEXT.Client]: store.getters['context/getSelectedClients'],
                [CONTEXT.Site]: store.getters['context/getSelectedSite']
            };
        },
        getOptions() {
            this.options = {
                [CONTEXT.Client]: store.getters['context/getData'],
                [CONTEXT.Site]: store.getters['context/getSites']
            };
        },
        equal(arr1, arr2) {
            return (
                arr1.length === arr2.length &&
                arr1.every(item => {
                    return arr2.indexOf(item) > -1;
                }) &&
                arr2.every(item => {
                    return arr1.indexOf(item) > -1;
                })
            );
        }
    }
};
</script>

<style lang="scss">
.multiselect {
    width: 100%;
    min-width: 200px;
    font-size: 14px;

    &__tags,
    &__spinner,
    &__input,
    &__single {
        background-color: #f5f6f7;
        font-size: 14px;
    }

    &__select {
        width: 34px;
        height: 34px;

        &:before {
            top: 59%;
        }
    }

    &__tags {
        min-height: 34px;
        padding: 6px 40px 0 6px;
    }

    &__single {
        margin-bottom: 6px;
        white-space: nowrap;
    }

    &__placeholder {
        margin-bottom: 6px;
        padding: 0;
    }

    &__option {
        padding: 9px 12px;
        min-height: 34px;
    }

    &-filter-button {
        padding: 2px;

        svg {
            width: 28px;
            height: 28px;
        }
    }
}
</style>
