const resource = '/quotations';
export default http => ({
    getQuotations(filters) {
        return http.post(`${resource}/pending`, filters);
    },
    validateQuotation(id, memo) {
        return http.post(`${resource}/${id}/approve`, { memo });
    },
    cancelQuotation(id, memo, reason) {
        return http.post(`${resource}/${id}/canceled`, { memo, reason });
    },
    getTreatedQuotations(filters) {
        return http.post(`${resource}/handled`, filters);
    },
    getQuotationsPendingCount(filters) {
        return http.post(`${resource}/pending/count`, filters);
    }
});
