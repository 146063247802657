const resource = '/sst/baf';

export default http => ({
    loadInterventionRequests() {
        return http.get(`${resource}/intervention_request/unassigned`);
    },
    currentBaf() {
        return http.get(`${resource}/current`);
    },
    updateInterventionQuotation(id, data) {
        return http.put(`${resource}/intervention_request/${id}`, data);
    },
    createIntervention(id) {
        return http.post(`${resource}/intervention_request/${id}`);
    },
    deleteIntervention(id) {
        return http.delete(`${resource}/intervention_request/${id}`);
    },
    cancelIntervention(id) {
        return http.post(`${resource}/intervention_request/${id}/cancel`);
    },
    previousBaf() {
        return http.get(`${resource}/previous`);
    },
    oneBafById(id) {
        return http.get(`${resource}/${id}`);
    },
    createBaf(formData) {
        return http.post(`${resource}/current`, formData);
    },
});
