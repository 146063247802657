import Vue from 'vue';

// Layouts
import ClientLayout from './ClientLayout.vue';
import SubcontractorLayout from './SstLayout.vue';
import AuthLayout from './AuthLayout.vue';
import PdfLayout from './PdfLayout.vue';
import GuestSubcontractorLayout from './GuestSstLayout.vue';

// Register Layouts
Vue.component('client-layout', ClientLayout);
Vue.component('sst-layout', SubcontractorLayout);
Vue.component('auth-layout', AuthLayout);
Vue.component('pdf-layout', PdfLayout);
Vue.component('guest-sst-layout', GuestSubcontractorLayout);
