<template>
    <div class="app-burger" v-if="canShowMenuBurgher">
        <b-button variant="outline" @click="toggle()">
            <i :class="iconClass"></i>
        </b-button>
    </div>
</template>

<script>
import { SIDEBAR_TOGGLE } from '@/store/actions/sidebar';

export default {
    name: 'Burger',
    data() {
        return {
            isOpen: false,
            windowWidth: 0,
            screenBreakpoint: 768
        };
    },
    methods: {
        toggle(forceState = null) {
            const el = document.body;
            this.isOpen = forceState !== null ? forceState : !this.isOpen;
            el.classList[this.isOpen ? 'add' : 'remove']('sidebar-open');
            this.$store.dispatch(`sidebar/${SIDEBAR_TOGGLE}`, this.isOpen);
        },
        getWindowWidth() {
            this.windowWidth = parseInt(document.documentElement.clientWidth, 10);
            if (this.windowWidth < this.screenBreakpoint) {
                this.toggle(false);
            }
        }
    },
    computed: {
        iconClass() {
            return `fa fa-${this.$store.getters['sidebar/isToggle'] ? 'times' : 'bars'}`;
        },
        canShowMenuBurgher() {
            return this.windowWidth < this.screenBreakpoint;
        }
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.getWindowWidth);
            this.getWindowWidth();
        });
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.getWindowWidth);
    }
};
</script>

<style scoped lang="scss">
.app-burger {
    position: relative;

    .fa {
        font-size: 1.25rem;
        margin-top: 6px;
    }
}
</style>
