import axios from 'axios';
import store from '../../store';
import NProgress from 'nprogress';
import { AUTH_REFRESH_TOKEN } from '@/store/actions/auth';
import router from '@/router';

class HttpClient {
    constructor() {
        this.instance = axios.create({
            baseURL: process.env.VUE_APP_API_URL || 'https://darwin.dev3.tekoway.com/api',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        });

        return this.init();
    }

    init() {
        // Apply request interceptors
        this.instance.interceptors.request.use(this.handleRequest);

        // Apply response interceptors
        this.instance.interceptors.response.use(this.handleResponseSuccess, this.handleResponseError.bind(this));

        return this.instance;
    }

    handleRequest(config) {
        // handle config to set authorization bearer token
        if (store.getters['auth/getUserToken']) {
            config.headers.common = { Authorization: `Bearer ${store.getters['auth/getUserToken']}` };
        }
        NProgress.start();
        return config;
    }

    handleResponseSuccess(response) {
        NProgress.done();
        return response;
    }

    handleResponseError(error) {
        console.log(error);
        if (error && error.response && error.response.status) {
            switch (error.response.status) {
                case 400:
                    console.error(error.response.status, error.message);
                    break;
                case 401: // authentication error handler
                    return this.handle401Error(error);
                case 503:
                    return this.handle503Error();
                default:
                    console.log(error);
                    break;
            }
        }
        NProgress.done();

        return Promise.reject(error);
    }

    handle401Error(error) {
        store.dispatch(`auth/${AUTH_REFRESH_TOKEN}`).then(response => {
            const config = error.config;
            config.headers['Authorization'] = `Bearer ${response.data.data.access_token}`;

            return new Promise((resolve, reject) => {
                axios
                    .request(config)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        });
    }

    handle503Error() {
        NProgress.done();
        router.push({ name: 'Maintenance' });
    }
}

export default new HttpClient();
