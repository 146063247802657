<template>
    <div class="layout layout--default layout--subcontractor">
        <b-navbar class="app-header" type="light" :sticky="true">
            <b-container class="px-0">
                <b-navbar-brand :to="{ name: homeUrl() }">
                    <img :src="logo" alt="Phinelec.fr" />
                </b-navbar-brand>
            </b-container>
            <div class="divider"></div>
        </b-navbar>

        <main class="app-main" role="main">
            <div class="app-main__inner">
                <slot />
            </div>

            <transition name="fade" mode="out-in" appear>
                <Footer />
            </transition>
        </main>
    </div>
</template>

<script>
import Footer from './components/Footer';

export default {
    name: 'SubcontractorLayout',
    components: {
        Footer
    },
    data() {
        return {
            logo: require('@/assets/logo-400.png')
        };
    },
    methods: {
        homeUrl() {
            if (this.$store.getters['auth/isAuthenticated']) {
                if (this.$store.getters['auth/isClientUser']) {
                    return 'ClientHome';
                }
                return 'SstHome';
            }
            return 'Login';
        }
    }
};
</script>
<style scoped lang="scss">
.app-header {
    width: 100%;
    flex-direction: column;
    padding: 0;
    background-color: $white;
    z-index: $layout-header-zIndex;
    transition: all 0.2s;
    border-bottom: 1px solid rgba(gray('300'), 0.5);

    .divider {
        width: 100%;
        height: 0;
        border-bottom: 1px solid rgba(gray('300'), 0.5);
    }

    .navbar-brand {
        img {
            height: 40px;
            width: auto;
        }
    }

    @include media-breakpoint-down(md) {
        .navbar-brand {
            padding-left: 10px;
        }
    }
}
</style>
