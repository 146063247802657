import {
    CLOSE_SELECTBOX,
    UPDATE_SELECTED_STATE,
    UPDATE_SELECTED_STATE_FOR_CLIENT,
    UPDATE_SELECTED_STATE_FOR_SITES,
    USER_CONTEXT,
    INPUT_HEADER_CHANGED,
    RESET_FILTER
} from '@/store/actions/context';

import { CONTEXT, USER_TYPE } from '@/constants';

// Actions
const actions = {
    [USER_CONTEXT]: ({ commit }, data) => {
        commit(USER_CONTEXT, data);
    },
    [UPDATE_SELECTED_STATE]: ({ commit }, [data, isSelected = false, type]) => {
        commit(UPDATE_SELECTED_STATE, { data, isSelected, type });
    },
    [UPDATE_SELECTED_STATE_FOR_SITES]: ({ commit }, [selectedClients]) => {
        commit(UPDATE_SELECTED_STATE_FOR_SITES, { selectedClients });
    },
    [UPDATE_SELECTED_STATE_FOR_CLIENT]: ({ commit }, [selectedSite]) => {
        commit(UPDATE_SELECTED_STATE_FOR_CLIENT, { selectedSite });
    },
    [CLOSE_SELECTBOX]: ({ commit }) => {
        commit(CLOSE_SELECTBOX);
    },
    [INPUT_HEADER_CHANGED]: ({ commit }) => {
        commit(INPUT_HEADER_CHANGED);
    },
    [RESET_FILTER]: ({ commit }) => {
        commit(RESET_FILTER);
    }
};

// Mutations
const mutations = {
    [USER_CONTEXT]: (state, data) => {
        data.filter(item => item.type === USER_TYPE.Client).forEach(client => {
            client.selected = true;
            client.sites.forEach(site => {
                site.selected = false;
            });
        });
        state.data = data;
    },
    [UPDATE_SELECTED_STATE]: (state, { data, isSelected, type }) => {
        if (type === CONTEXT.Client) {
            data.selected = isSelected;
            state.data.forEach(element => {
                if (element.id === data.id) {
                    state.data.selected = isSelected;
                }
            });
        } else {
            state.data.forEach((client, clientIndex) => {
                client.sites.forEach((site, siteIndex) => {
                    if (site.id === data.id) {
                        state.data[clientIndex].sites[siteIndex].selected = isSelected;
                    } else {
                        state.data[clientIndex].sites[siteIndex].selected = false;
                    }
                });
            });
        }
    },
    [UPDATE_SELECTED_STATE_FOR_SITES]: (state, { selectedClients }) => {
        if (selectedClients.length === 0) {
            state.data.forEach((client, clientIndex) => {
                client.sites.forEach((site, siteIndex) => {
                    state.data[clientIndex].sites[siteIndex].selected = false;
                });
            });
        } else {
            state.data.forEach((client, clientIndex) => {
                if (selectedClients.includes(client)) {
                    if (!client.sites.includes(getters.getSelectedSite(state)[0])) {
                        client.sites.forEach((site, siteIndex) => {
                            state.data[clientIndex].sites[siteIndex].selected = false;
                        });
                    }
                } else {
                    if (client.sites.includes(getters.getSelectedSite(state)[0])) {
                        client.sites.forEach((site, siteIndex) => {
                            state.data[clientIndex].sites[siteIndex].selected = false;
                        });
                    }
                }
            });
        }
    },
    [UPDATE_SELECTED_STATE_FOR_CLIENT]: (state, { selectedSite }) => {
        state.data.forEach((client, clientIndex) => {
            if (!client.sites.includes(selectedSite)) {
                state.data[clientIndex].selected = false;
            }
        });
    },
    [CLOSE_SELECTBOX]: state => {
        if (getters.getSelectedClients(state).length === 0) {
            state.data.forEach(client => {
                client.selected = true;
            });
        }
    },
    [INPUT_HEADER_CHANGED]: state => {
        if (getters.getSelectedClients(state).length === 0) {
            state.data.forEach(client => {
                client.selected = true;
            });
        }
    },
    [RESET_FILTER]: state => {
        state.data.forEach((client, clientIndex) => {
            client.sites.forEach((site, siteIndex) => {
                state.data[clientIndex].sites[siteIndex].selected = false;
            });
            client.selected = true;
        });
    }
};

// Getters
const getters = {
    getData: state => state.data,
    getClients: state => {
        return state.data;
    },
    getSelectedClients: state => {
        return state.data.filter(option => option.selected === true);
    },
    getSites: state => {
        let sites = [];
        state.data.forEach(client => {
            if (client.selected) {
                sites.push(...client.sites);
            }
        });
        return sites;
    },
    getSelectedSite: state => {
        return state.data.reduce((acc, value) => {
            return acc.concat(value.sites.filter(option => option.selected === true));
        }, []);
    },
    getGlobalFiltersIds: state => {
        let global_filters = {
            clients: [],
            site: null
        };
        let selectedClients = getters.getSelectedClients(state);
        let selectedSite = getters.getSelectedSite(state);

        if (selectedClients) {
            global_filters.clients = selectedClients.map(a => a.client_id);
        }

        if (selectedSite.length > 0) {
            global_filters.site = selectedSite[0].id;
        }

        return global_filters;
    },
    isClientUser: state => {
        return state.data.filter(item => item.type === USER_TYPE.Client).length > 0;
    },
    isSstUser: state => {
        return state.data.filter(item => item.type === USER_TYPE.Subcontractor).length > 0;
    },
    clientHasMetalRolloutCampaign: state => {
        state.data.forEach(client => {
            if (client.metal_rollout_campaign && client.metal_rollout_campaign === true) {
                return true;
            }
        });
        return false;
    },
    sstHasMetalRolloutAccess: state => {
        let found = false;
        state.data.forEach(sst => {
            if (sst.metal_rollout_access === true) {
                found = true;
            }
        });
        return found;
    }
};

export default {
    namespaced: true,
    getters,
    actions,
    mutations
};
