const resource = '/site';

export default http => ({
    searchSitesWithMetalRollouts(search) {
        return http.get(`${resource}/metal_rollouts?query=` + search);
    },
    metalRolloutsBySite(siteId) {
        return http.get(`${resource}/${siteId}/metal_rollouts`);
    },
    create(formData, siteId) {
        return http.post(`${resource}/${siteId}/metal_rollouts/create`, formData);
    },
    update(formData, metalRolloutId) {
        return http.put(`${resource}/metal_rollouts/${metalRolloutId}/update`, formData);
    },
    delete(metalRolloutId) {
        return http.delete(`${resource}/metal_rollouts/${metalRolloutId}/delete`);
    }
});
