import i18n from '@/i18n';

export const RADIO_BUTTONS_KEYS = {
    ROLLING_YEAR: 'rollingYear',
    CURRENT_YEAR: 'currentYear',
    DYNAMIC_PERIOD: 'dynamicPeriod'
};

export const COST = {
    RADIO_BUTTONS: {
        OPTIONS: [
            {
                text: i18n.t('client.pages.costs.header.radioButtons.rollingYear'),
                value: RADIO_BUTTONS_KEYS.ROLLING_YEAR
            },
            {
                text: i18n.t('client.pages.costs.header.radioButtons.currentYear'),
                value: RADIO_BUTTONS_KEYS.CURRENT_YEAR
            },
            {
                text: i18n.t('client.pages.costs.header.radioButtons.dynamicPeriod'),
                value: RADIO_BUTTONS_KEYS.DYNAMIC_PERIOD
            }
        ]
    }
};
