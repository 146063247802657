import Vue from 'vue';
import VueRouter from 'vue-router';
import EmptyRouterView from '@/layouts/EmptyRouterView';
import store from '@/store';
import { SET_GUEST_USER } from '../store/actions/auth';

Vue.use(VueRouter);

const deAuthenticateUser = (_to, _from, next) => {
    store.dispatch(`auth/${SET_GUEST_USER}`);
    next();
};

export default [
    {
        path: '/guest',
        component: EmptyRouterView,
        beforeEnter: deAuthenticateUser,
        redirect: '/',
        children: [
            {
                path: 'sst',
                component: EmptyRouterView,
                redirect: '/',
                children: [
                    {
                        path: 'interventions',
                        component: EmptyRouterView,
                        redirect: '/',
                        children: [
                            {
                                path: ':id',
                                name: 'GuestSstInterventionsShow',
                                component: () => import('../views/sst/intervention/InterventionShow')
                            }
                        ]
                    }
                ]
            }
        ]
    }
];
