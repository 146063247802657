export const TABLE = {
    PAGE: {
        CLIENT: {
            INVOICE: {
                ID: 'client-invoice',
                EXCEL_URL: 'payment_requests/excel',
                PDF_URL: 'payment_requests/{id}/download',
                PDF_FILENAME: 'facture_{quoteNumber}',
                EXCEL_FILENAME: 'export_factures_{date}.xlsx'
            },
            QUOTATION: {
                ID: 'client-quotation',
                EXCEL_URL: 'quotations/pending/excel',
                PDF_URL: 'quotations/{id}/download',
                PDF_FILENAME: 'devis_{quoteId}',
                EXCEL_FILENAME: 'export_devis_attente_{date}.xlsx'
            },
            TREATED_QUOTATION: {
                ID: 'client-treatedQuotation',
                PDF_URL: 'quotations/{id}/download',
                PDF_FILENAME: 'devis_{quoteId}',
                EXCEL_FILENAME: 'export_devis_attente_{date}.xlsx'
            },
            INTERVENTION: {
                ID: 'client-intervention',
                EXCEL_URL: 'intervention_requests/xls',
                EXCEL_FILENAME: 'B2B Maintenance - Export interventions - Interventions {status} - {date}.xlsx'
            },
            INTERVENTION_METAL_ROLLOUTS: {
                ID: 'client-intervention-metal-rollouts',
                EXCEL_URL: 'metal_rollouts/intervention_requests_by_site_xls',
                EXCEL_FILENAME: 'B2B Maintenance - Export client - Rideaux métalliques - {date}.xlsx'
            }
        },
        SST: {
            INVOICE: {
                PAID: {
                    ID: 'sst-invoice-paid'
                },
                PENDING: {
                    ID: 'sst-invoice-pending'
                }
            },
            INTERVENTION: {
                ID: 'sst-intervention',
                DOWNLOAD_FILE_URL: 'sst/purchases/{purchaseId}/download_by_id/{documentId}',
                VOUCHER_PDF_URL: 'sst/purchases/{purchaseId}/download_by_type/{documentType}'
            },
            ACCOUNT_DOCUMENTS: {
                DOWNLOAD_FILE_URL: 'sst/'
            },
            METAL_ROLLOUT: {
                ID: 'sst-metal-rollout'
            },
            BAF: {
                ID: 'sst-baf'
            },
            PREVIOUS_BAF: {
                ID: 'sst-previous-baf'
            }
        },
        GUEST_SST: {
            INTERVENTION: {
                ID: 'sst-intervention',
                DOWNLOAD_FILE_URL: 'guest/purchases/{purchaseId}/download_by_id/{documentId}',
                VOUCHER_PDF_URL: 'guest/purchases/{purchaseId}/download_by_type/{documentType}'
            }
        }
    }
};
