import HttpClient from './clients/httpClient';
import Auth from './auth';
import Context from './context';
import Dashboard from './dashboard';
import Payment from './payment';
import Quotation from './quotation';
import File from './file';
import Sst from './sst';
import GuestSst from './guest_sst';
import Intervention from './intervention';
import MetalRollouts from './metal_rollouts';
import Site from './site';
import Helper from './helper';
import Baf from './baf';

export const AuthRepository = Auth(HttpClient);
export const ContextRepository = Context(HttpClient);
export const DashboardRepository = Dashboard(HttpClient);
export const PaymentRepository = Payment(HttpClient);
export const QuotationRepository = Quotation(HttpClient);
export const FileRepository = File(HttpClient);
export const SstRepository = Sst(HttpClient);
export const GuestSstRepository = GuestSst(HttpClient);
export const InterventionRepository = Intervention(HttpClient);
export const MetalRolloutsRepository = MetalRollouts(HttpClient);
export const SiteRepository = Site(HttpClient);
export const HelperRepository = Helper(HttpClient);
export const BafRepository = Baf(HttpClient);

export default {
    AuthRepository,
    ContextRepository,
    DashboardRepository,
    PaymentRepository,
    QuotationRepository,
    FileRepository,
    SstRepository,
    GuestSstRepository,
    InterventionRepository,
    MetalRolloutsRepository,
    SiteRepository,
    HelperRepository,
    BafRepository
};
