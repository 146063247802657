const resource = '/sst';

export default http => ({
    getInterventions() {
        return http.get(`${resource}/purchases`);
    },
    getPayedInvoices() {
        return http.get(`${resource}/invoices/paid`);
    },
    getPendingInvoices() {
        return http.get(`${resource}/invoices/pending`);
    },
    getDashboardData() {
        return http.get(`${resource}/dashboard`);
    },
    getPurchase(id) {
        return http.get(`${resource}/purchases/${id}`);
    },
    uploadDocument(id, formData) {
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        return http.post(`${resource}/purchases/${id}/upload`, formData, config);
    },
    accountData() {
        return http.get(`${resource}/account`);
    },
    uploadAccountDocument(formData) {
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        return http.post(`${resource}/account/documents/upload`, formData, config);
    }
});
