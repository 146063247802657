import Vue from 'vue';
import VueRouter from 'vue-router';
import EmptyRouterView from '@/layouts/EmptyRouterView';
import store from '@/store';

Vue.use(VueRouter);

const ifAuthenticated = (_to, _from, next) => {
    if (store.getters['auth/isAuthenticated'] && store.getters['auth/isClientUser']) {
        next();
        return;
    }

    next('/');
};

export default [
    {
        path: '/client',
        beforeEnter: ifAuthenticated,
        component: EmptyRouterView,
        children: [
            {
                path: 'accueil',
                name: 'ClientHome',
                component: () => import('../views/client/Home')
            },
            {
                path: 'payments',
                beforeEnter: ifAuthenticated,
                component: EmptyRouterView,
                children: [
                    {
                        path: 'factures',
                        name: 'ClientPendingInvoice',
                        component: () => import('../views/client/invoice/quote/PendingInvoice')
                    }
                ]
            },
            {
                path: 'dashboards',
                beforeEnter: ifAuthenticated,
                component: EmptyRouterView,
                children: [
                    {
                        path: 'costs',
                        name: 'ClientCosts',
                        component: () => import('../views/client/dashboard/Costs')
                    },
                    {
                        path: 'interventions',
                        name: 'ClientInterventions',
                        component: () => import('../views/client/dashboard/Interventions')
                    },
                    {
                        path: 'synthesis',
                        name: 'ClientSynthesis',
                        component: () => import('../views/client/dashboard/Synthesis')
                    },
                    {
                        path: 'indicators',
                        name: 'ClientPerformanceIndicators',
                        component: () => import('../views/client/dashboard/PerformanceIndicators')
                    },
                    {
                        path: 'valeurs',
                        name: 'ClientDistributionByValue',
                        component: () => import('../views/client/dashboard/DistributionByValue')
                    },
                    {
                        path: 'pourcentages',
                        name: 'ClientDistributionByPercentage',
                        component: () => import('../views/client/dashboard/DistributionByPercentage')
                    }
                ]
            },
            {
                path: 'devis',
                component: EmptyRouterView,
                children: [
                    {
                        path: 'attente',
                        name: 'ClientPendingQuote',
                        component: () => import('../views/client/invoice/quote/PendingQuote')
                    },
                    {
                        path: 'traite',
                        name: 'ClientTreatedQuote',
                        component: () => import('../views/client/invoice/quote/TreatedQuote')
                    }
                ]
            },
            {
                path: 'profile',
                beforeEnter: ifAuthenticated,
                component: EmptyRouterView,
                children: [
                    {
                        path: 'my-account',
                        name: 'ClientMyAccount',
                        component: () => import('../views/client/user/MyAccount')
                    }
                ]
            },
            {
                path: 'interventions',
                beforeEnter: ifAuthenticated,
                component: EmptyRouterView,
                children: [
                    {
                        path: 'liste',
                        name: 'ClientInterventionList',
                        component: () => import('../views/client/intervention/InterventionList')
                    }
                ]
            },
            {
                path: 'preventif',
                beforeEnter: ifAuthenticated,
                component: EmptyRouterView,
                children: [
                    {
                        path: 'rm',
                        name: 'ClientInterventionMetalRollouts',
                        component: () => import('../views/client/intervention/InterventionMetalRollouts')
                    }
                ]
            }
        ]
    }
];
