import {
    TABLE_RADIO_TAB_CHANGE,
    TABLE_FILTER_CHANGE,
    TABLE_SORTING_CHANGE,
    TABLE_PAGE_CHANGE,
    TABLE_SCROLL_POSITION_CHANGE,
    TABLE_FILTERED_ITEMS_LENGTH_CHANGE
} from '../actions/interventionTableState';
import { RESET } from '@/store/actions/commonActions';

// Initial State
const initialState = () => ({
    radioTab: '',
    searchFilter: '',
    sortBy: null,
    sortDesc: false,
    page: null,
    scrollPosition: 0,
    filteredItemsLength: null
});

// State
const state = initialState();

// Actions
const actions = {
    [TABLE_RADIO_TAB_CHANGE]: ({ commit }, radioTab) => {
        commit(TABLE_RADIO_TAB_CHANGE, radioTab);
        commit(TABLE_SCROLL_POSITION_CHANGE, 0);
        commit(TABLE_PAGE_CHANGE, 1);
        commit(TABLE_FILTERED_ITEMS_LENGTH_CHANGE, null);
    },
    [TABLE_FILTER_CHANGE]: ({ commit }, filterValue) => {
        commit(TABLE_FILTER_CHANGE, filterValue);
    },
    [TABLE_SORTING_CHANGE]: ({ commit }, { sortBy, sortDesc }) => {
        commit(TABLE_SORTING_CHANGE, { sortBy, sortDesc });
    },
    [TABLE_PAGE_CHANGE]: ({ commit }, page) => {
        commit(TABLE_PAGE_CHANGE, page);
    },
    [TABLE_SCROLL_POSITION_CHANGE]: ({ commit }, position) => {
        commit(TABLE_SCROLL_POSITION_CHANGE, position);
    },
    [TABLE_FILTERED_ITEMS_LENGTH_CHANGE]: ({ commit }, length) => {
        commit(TABLE_FILTERED_ITEMS_LENGTH_CHANGE, length);
    },
    [RESET]: ({ commit }) => {
        commit(RESET);
    }
};

// Mutations
const mutations = {
    [TABLE_RADIO_TAB_CHANGE]: (state, radioTab) => {
        state.radioTab = radioTab;
    },
    [TABLE_FILTER_CHANGE]: (state, filterValue) => {
        state.searchFilter = filterValue;
    },
    [TABLE_SORTING_CHANGE]: (state, { sortBy, sortDesc }) => {
        state.sortBy = sortBy;
        state.sortDesc = sortDesc;
    },
    [TABLE_PAGE_CHANGE]: (state, page) => {
        state.page = page;
    },
    [TABLE_SCROLL_POSITION_CHANGE]: (state, position) => {
        state.scrollPosition = position;
    },
    [TABLE_FILTERED_ITEMS_LENGTH_CHANGE]: (state, length) => {
        state.filteredItemsLength = length;
    },
    [RESET]: state => {
        Object.assign(state, initialState());
    }
};

// Getters
const getters = {
    radioTab: state => state.radioTab,
    searchFilter: state => state.searchFilter,
    sortBy: state => state.sortBy,
    sortDesc: state => state.sortDesc,
    page: state => state.page,
    scrollPosition: state => state.scrollPosition,
    filteredItemsLength: state => state.filteredItemsLength
};

export default {
    state,
    namespaced: true,
    getters,
    actions,
    mutations
};
