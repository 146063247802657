<template>
    <b-modal
        @hidden="resetModal"
        @ok="handleOk"
        @show="resetModal"
        :cancel-title="$t('shared.general.buttons.cancel')"
        id="reset-password-modal"
        ref="modal"
        :title="$t('shared.components.resetPassword.title')"
    >
        <span>{{ $t('shared.components.resetPassword.info') }}</span>
        <Validation-observer ref="resetPasswordObserver" v-slot="{ handleSubmit }">
            <b-form @submit.prevent="handleSubmit(handleOk)">
                <Validation-provider
                    :name="$t('auth.login.labels.emailResetPassword')"
                    :rules="{ required: true, email: true }"
                    v-slot="validationContext"
                >
                    <b-form-group>
                        <b-form-input
                            id="resetPasswordEmail"
                            class="mt-2"
                            type="text"
                            v-model="form.email"
                            :state="getValidationState(validationContext)"
                            :placeholder="$t('auth.login.labels.emailResetPassword')"
                        ></b-form-input>

                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                </Validation-provider>
            </b-form>
        </Validation-observer>
    </b-modal>
</template>

<script>
import { AuthRepository } from '@/repositories';

export default {
    name: 'ResetPasswordRequest',
    data() {
        return {
            form: {
                email: null
            }
        };
    },
    methods: {
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        resetModal() {
            this.form.email = '';
        },
        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.resetPassword();
        },
        async resetPassword() {
            const isValid = await this.$refs.resetPasswordObserver.validate();
            if (!isValid) {
                return;
            }
            AuthRepository.resetPasswordRequest(this.form.email)
                .then(() => {
                    // Hide the modal manually
                    this.$nextTick(() => {
                        this.$bvModal.hide('reset-password-modal');
                        this.$toast.success(this.$i18n.t('auth.resetPasswordRequest.notification.success'));
                    });
                })
                .catch(error => {
                    // Hide the modal manually
                    this.$nextTick(() => {
                        this.$bvModal.hide('reset-password-modal');
                        this.$toast.error(error.response.data.message);
                    });
                });
        }
    }
};
</script>
