export const DASHBOARD_CLIENT = {
    DOUGHNUT_COLORS_BY_SKILL_ID: {
        1: '#3366cc',
        2: '#dc3912',
        3: '#ff9900',
        4: '#109618',
        5: '#990099',
        6: '#0099c6',
        7: '#dd4477',
        8: '#66aa00',
        9: '#b82e2e',
        10: '#316395',
        11: '#994499',
        12: '#22aa99',
        13: '#aaaa11',
        14: '#6633cc',
        15: '#e67300',
        16: '#8b0707',
        17: '#651067',
        18: '#329262',
        19: '#5574a6',
        20: '#3b3eac',
        21: '#b77322',
        22: '#16d620',
        23: '#b91383',
        24: '#f4359e',
        25: '#9c5935',
        26: '#a9c413',
        27: '#2a778d',
        28: '#668d1c',
        29: '#bea413',
        30: '#0c5922'
    },
    COSTS_COUNTS_INTERVENTIONS: {
        EXPORT: {
            EXCEL_URL: 'dashboard/client-excel-export/{type}',
            EXCEL_FILENAME: 'B2B Maintenance - Export interventions - Interventions from page {page} - {date}.xlsx'
        }
    }
};
